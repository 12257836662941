<template>
  <div style="width: 100%;height: 100%; background: #fff;" >
    <el-form :inline="true"  @input="change($event)" :model="recordsForm" ref="recordsForm" class="recordsForm">
      <el-form-item label="编号" prop="record_id">
        <el-input v-model="recordsForm.record_id" @keyup.enter.native="submitSearch" clearable placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone_number">
        <el-input v-model="recordsForm.phone_number" @keyup.enter.native="submitSearch" clearable placeholder="请输入"/>
      </el-form-item>
    <div id="foldBox">
      <el-form-item label="短信主题" prop="sms_theme" >
        <el-input  v-model="recordsForm.sms_theme" @keyup.enter.native="submitSearch" clearable placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="短信发送状态" prop="sms_status" >
        <el-select  v-model="recordsForm.sms_status" clearable placeholder="请选择" >
            <el-option value="成功" label="成功"/>
            <el-option value="失败" label="失败"/>
            <el-option value="发送中" label="发送中"/>
          </el-select>
      </el-form-item>
    </div>
      <el-form-item>
      <el-button icon="el-icon-search" type="primary" @click="submitSearch()" v-preventReClick>查询</el-button>
      <el-button icon="el-icon-refresh" @click="resetForm('recordsForm')" v-preventReClick>重置</el-button>
      <a  class="advanced" @click="toggleAdvanced">
        {{ advanced ? '展开' : '收起' }}
        <i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down' "></i>
      </a>
      </el-form-item>
    </el-form>
     <el-divider></el-divider>
    <el-table 
        :data="records" 
        row-key="id" 
        :header-cell-style="headClass" 
        id="table" 
        v-loading="pictLoading" 
        element-loading-background="rgb(255,255,255))"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading">
      <el-table-column prop="id" label="编号"  align="center" min-width="40" ></el-table-column>
      <el-table-column prop="template_id" label="模板ID"  align="center" min-width="40">
        <template slot-scope="scope">
           {{scope.row.template_id?scope.row.template_id:'--'}}
        </template>
      </el-table-column>
      <el-table-column prop="sms_theme" label="短信主题"  align="center">
         <template slot-scope="scope">
           {{scope.row.sms_theme?scope.row.sms_theme:'--'}}
        </template>
      </el-table-column>
      <el-table-column prop="phone_number" label="电话号码"  align="center"></el-table-column>
      <el-table-column prop="sms_send_approval_status" label="短信发送审核状态"  align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.sms_send_approval_status === '成功'">{{scope.row.sms_send_approval_status}}</el-tag>
          <el-tag v-if="scope.row.sms_send_approval_status === '审核中'">{{scope.row.sms_send_approval_status}}</el-tag>
          <el-tag type="danger" v-if="scope.row.sms_send_approval_status === '失败'">{{scope.row.sms_send_approval_status}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sms_hook_status" label="短信发送状态"  align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.sms_hook_status === '成功'">{{scope.row.sms_hook_status}}</el-tag>
          <el-tag  v-if="scope.row.sms_hook_status === '发送中'">{{scope.row.sms_hook_status}}</el-tag>
          <el-popover
            placement="top-start"
            title="失败原因"
            width="200"
            trigger="hover">
            <slot> <i class="el-icon-warning-outline" style="color:red "></i> <span v-if="scope.row.failure_reason===''">暂无</span> {{ scope.row.failure_reason }}</slot>
           <el-tag type="danger" v-show="scope.row.sms_hook_status === '失败'" slot="reference">{{scope.row.sms_hook_status}}</el-tag>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="message_content" label="发送内容" align="center" show-overflow-tooltip ></el-table-column>
      <el-table-column prop="created_at" label="发送时间"  align="center"></el-table-column>
    </el-table>
    <div>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="recordsForm.current_page"
        :limit.sync="recordsForm.page_size"
        @pagination="getSmsRecord"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      records: [],
      pictLoading:false,
      total: 0, 
      recordsForm:{
          page_size: 10, 
          current_page: 1,
          record_id:'',
          phone_number:'',
          sms_theme:'',
          sms_status:''
      },
      advanced:false,
    }
  },

  mounted() {
    this.$nextTick(function() {
      this.toggleAdvanced();
    })
  },
  created() {
    this.getSmsRecord();
      },
  methods: {
     toggleAdvanced(){
       this.advanced = !this.advanced;
       var foldBoxHeight= document.getElementById("foldBox");
       if (this.advanced == true) {
        foldBoxHeight.style.height = "auto";
        document.getElementById("foldBox").style.display='none';
      } else {
        document.getElementById("foldBox").style.display='inline-block';
      }
     },
     
    headClass() {
      return 'background: #f5f5f7;border-bottom:none;color:rgba(0,0,0,.85)'
      },

    change(e) {
      this.$forceUpdate()
    },

    async getSmsRecord() {
       this.pictLoading = true
      let params = {
        ...this.recordsForm
      };
      const res = await this.$http.get("/message_record/get", params);
       this.pictLoading = false
        if (res.data.code!==0) { return errorAlert(res.data.msg)}
        this.records = res.data.data.records;
        this.total = res.data.data.total
        this.pictLoading = false
    },

    submitSearch(){
       this.recordsForm.current_page=1
      this.getSmsRecord();
    },
      
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.submitSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.recordsForm{
  margin-left: 30px;
  margin-bottom: 10px;
}
.el-table{
  margin: 10px 10px 0 10px;
}
.el-form-item{
    margin-top: 20px;
}
/deep/.ui-text-btns {
  span {
    margin-left: 4px !important;
  }
  .el-link {
    margin-left: 10px;
  }
}
/deep/.el-form-item--small .el-form-item__content {
    margin-right: 30px;
}
.advanced{
  margin-left:8px;
  color: #409EFF;
}
#foldBox{
  overflow: hidden;
}
/deep/.popoverSize{ 
  font-size: 12px;
  }
.el-table{
  color: #000;
  }

/deep/.el-table .cell {
    white-space: pre !important;
}
/deep/.el-form-item--small.el-form-item {
    margin-bottom: 5px;
}
.icon-content{
  float: right;
  margin: 10px 20px 10px 0;
  .el-button--small.is-circle {
    padding: 4px;
}
}

</style>